import React, { Component, useState, useRef } from 'react';
import ShareLocation from '../../partials/share/ShareLocation';
import './Figma.scss';

const mobileModuleWidth = 375;

// Parse CSS as React camelCase
function parseCssString(cssString) {
  const cssObject = {};

  if (cssString) {
    // Split the string into individual styles
    const stylesArray = cssString.split(';');

    stylesArray.forEach(style => {
      if (style.trim() === '') return;

      // Split each style into property and value
      let [property, value] = style.split(':');

      if (property && value) {
        // Convert CSS property to camelCase
        const camelCaseProperty = property.trim().replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        if (property === 'top' || property === 'left' || property === 'width' || property === 'height') {
          //value = (parseFloat(value)/resizeRatio)+'px';
          cssObject[camelCaseProperty] = value;
        } else {
          cssObject[camelCaseProperty] = value.trim();
        }
      }
    });
  }

  return cssObject;
}

// Calculate viewport compensation
function calculateViewport(viewport) {
  return 1 + (1 - viewport);
}

// Calculate mobile font sizes, compensate scale
function calculateFontSize(text, viewport) {
  if (window.innerWidth > 768) {
    return text;
  }

  // Get all font-size declarations
  const regex = /font-size:\s*(\d+)px/g;

  return text.replace(regex, (match, p1) => {
    const newSize = parseInt(p1, 10) / viewport;
    return `font-size:${newSize}px`;
  });

  return text;
}

// Recursive Tree Component
const TreeNode = ({ node, items, viewport, props }) => {
  let [x, setX] = useState(0);
  let [y, setY] = useState(0);
  let [attachmentClass, setAttachmentClass] = useState('btn-download-float');
  const hoverVideo = useRef(null);
  const thisNode = findImageByNodeId(items, node.id);

  function findImageByNodeId(items, targetNodeId) {
    return items.find(item => item.node_id === targetNodeId);
  }

  function imageHasDownloads(node) {
    return node.attachments && node.attachments.length > 0;
  }

  function showModal(item) {
    let newItem = item;
    newItem.image = item.image;

    props.openModal(item, [], 0);
  }

  function formatUrl(url) {
    if (!isNaN(url) || url.charAt(0) === '+') {
      return 'tel:'+url;
    }

    if (url.indexOf('mailto:') === -1 && url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('@') !== -1) {
      return 'mailto:'+url;
    }

    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('mailto:') === -1 && url.indexOf('anchor:') === -1 && url.indexOf('page:') === -1) {
      return 'https://'+url;
    }

    return url;
  }

  function _onMouseEnter(e) {
    setAttachmentClass('btn-download-float visible');
    if (hoverVideo.current) {
      hoverVideo.current.currentTime = 0;
    }
  }

  function _onMouseMove(e) {
    const element = e.currentTarget; // The current hovered element
    const hoverDefault = element.querySelector('.hover-default');

    const parentOffset = hoverDefault.getBoundingClientRect();

    const relativeX = (e.clientX - parentOffset.left + (5 * 1.45)) / viewport;
    const relativeY = (e.clientY - parentOffset.top - (30 * viewport)) / viewport;

    setX(relativeX);
    setY(relativeY);

    setAttachmentClass('btn-download-float visible');
  }

  function _onMouseLeave(e) {
    setAttachmentClass('btn-download-float');
  }

  return (
    <div figma-id={node.id} figma-name={node.name} figma-type={node.type} style={parseCssString(node.css)}>
      {/* Image not fill */}
      {node.image && !node.imageFill && (
        <div style={{width: '100%', height: '100%'}} onMouseEnter={_onMouseEnter} onMouseMove={_onMouseMove} onMouseLeave={_onMouseLeave}>
          <div className={thisNode.hover ? 'image with--hover' : 'image'}>
            {!thisNode.url && (
              <div style={{cursor: imageHasDownloads(thisNode) ? 'pointer' : 'default', height: '100%'}} onClick={(e) => { imageHasDownloads(thisNode) ? showModal(thisNode) : e.preventDefault() }}>
                <div className="hover-default">
                  <img src={thisNode.image} alt="" />
                </div>

                {thisNode.hover &&
                  <div className="hover-hover">
                    {thisNode.hover_type === 'image' &&
                      <img src={thisNode.hover} alt="" />
                    }
                    {thisNode.hover_type === 'video' &&
                      <video ref={hoverVideo} autoPlay loop muted playsInline>
                        <source src={thisNode.hover} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    }
                  </div>
                }
              </div>
            )}

            {thisNode.url && (
              <div style={{height: '100%'}}>
                <div className="hover-default">
                  <div style={{cursor: 'pointer', height: '100%'}}>
                    <a href={formatUrl(thisNode.url)} target={thisNode.target} rel="noopener noreferrer">
                      <img src={thisNode.image} alt="" />
                    </a>
                  </div>
                </div>

                {thisNode.hover &&
                  <div className="hover-hover">
                    {thisNode.hover_type === 'image' &&
                      <img src={thisNode.hover} alt="" />
                    }
                    {thisNode.hover_type === 'video' &&
                      <video ref={hoverVideo} autoPlay loop muted playsInline>
                        <source src={thisNode.hover} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    }
                  </div>
                }
              </div>
            )}

            {imageHasDownloads(thisNode) && (
              <div className={attachmentClass} style={{left:x, top:y, transform: 'scale('+calculateViewport(viewport)+')'}} onClick={(e) => { e.preventDefault(); showModal(thisNode) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Image fill container */}
      {node.image && node.imageFill && (
        <div style={{width: '100%', height: '100%'}} onMouseEnter={_onMouseEnter} onMouseMove={_onMouseMove} onMouseLeave={_onMouseLeave}>
          <div className={thisNode.hover ? 'image with--hover' : 'image'}>
            {!thisNode.url && (
              <div style={{cursor: imageHasDownloads(thisNode) ? 'pointer' : 'default', height: '100%'}} onClick={(e) => { imageHasDownloads(thisNode) ? showModal(thisNode) : e.preventDefault() }}>
                <div className="hover-default" style={{background: 'url('+thisNode.image+') no-repeat', backgroundSize: 'contain'}}>
                  &nbsp;
                </div>

                {thisNode.hover &&
                  <div className="hover-hover">
                    {thisNode.hover_type === 'image' &&
                      <img src={thisNode.hover} alt="" />
                    }
                    {thisNode.hover_type === 'video' &&
                      <video ref={hoverVideo} autoPlay loop muted playsInline>
                        <source src={thisNode.hover} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    }
                  </div>
                }
              </div>
            )}

            {thisNode.url && (
              <div style={{height: '100%'}}>
                <div className="hover-default" style={{background: 'url('+thisNode.image+') no-repeat', backgroundSize: 'contain'}}>
                  <div style={{cursor: 'pointer', height: '100%'}}>
                    <a href={formatUrl(thisNode.url)} target={thisNode.target} rel="noopener noreferrer">&nbsp;</a>
                  </div>
                </div>

                {thisNode.hover &&
                  <div className="hover-hover">
                    {thisNode.hover_type === 'image' &&
                      <img src={thisNode.hover} alt="" />
                    }
                    {thisNode.hover_type === 'video' &&
                      <video ref={hoverVideo} autoPlay loop muted playsInline>
                        <source src={thisNode.hover} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    }
                  </div>
                }
              </div>
            )}

            {imageHasDownloads(thisNode) && (
              <div className={attachmentClass} style={{left:x, top:y, transform: 'scale('+calculateViewport(viewport)+')'}} onClick={(e) => { e.preventDefault(); showModal(thisNode) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Text node */}
      {node.text && (
        <span dangerouslySetInnerHTML={{__html: calculateFontSize(node.text, viewport)}}></span>
      )}

      {node.children && node.children.length > 0 && (
        node.children.map((child, index) => (
          <TreeNode key={index} node={child} items={items} viewport={viewport} props={props} />
        ))
      )}
    </div>
  );
};

class Figma extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      items: [],
      mobileScrollHintVisible: false,
      background: null,
      bg_image: null,
      bg_image_x: null,
      bg_image_y: null,
      bg_video: null,
      json: [],
      height: null,
      padding: null,
      padding_px: null,
      row_padding_bottom: null,
      row_padding_top: null,
      viewport: 1
    }

    this.handleViewportChange = this.handleViewportChange.bind(this);
    this.hideScrollHint = this.hideScrollHint.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      items: this.props.data.items,
    }, () => {
      // Call handleViewportChange after setting initial data
      this.handleViewportChange();
      window.addEventListener('resize', this.handleViewportChange);
    });
  }

  componentWillUnmount() {
    // Clean up event listener
    window.removeEventListener('resize', this.handleViewportChange);
  }

  // Hide mobile scroll hint on tap
  hideScrollHint() {
    const visibleHint = document.querySelector('.anchor.visible');
    if (visibleHint) {
      visibleHint.classList.remove('visible');
    }
  }

  // Calculate mobile content height
  calculateMobileHeight(viewport) {
    return viewport * this.state.data.height_mobile;
  }

  // Calculate viewport size and update state
  handleViewportChange() {
    const width = window.innerWidth;

    // Determine whether to use desktop or mobile data
    const isMobile = width <= 768 && this.state.data.data_mobile;
    const prefix = isMobile ? '_mobile' : '';

    // Calculate viewport for mobile
    let viewport = isMobile ? (width - 30)/mobileModuleWidth : this.state.data[`viewport${prefix}`];

    // Calculate viewport for mobile to desktop gap (769 - 1100)
    if (width > 768 && width < 1100) {
      viewport = width / 1100;
    }

    // Update state dynamically
    this.setState({
      background: this.state.data[`background${prefix}`],
      bg_image: this.state.data[`bg_image${prefix}`],
      bg_image_x: this.state.data[`bg_image_x${prefix}`],
      bg_image_y: this.state.data[`bg_image_y${prefix}`],
      bg_video: this.state.data[`bg_video${prefix}`],
      json: isMobile ? this.state.data.data_mobile : this.state.data.data_desktop,
      height: isMobile ? this.calculateMobileHeight(viewport) : this.state.data[`height${prefix}`],
      padding: this.state.data[`padding${prefix}`],
      padding_px: this.state.data[`padding_px${prefix}`],
      row_padding_bottom: this.state.data[`row_padding_bottom${prefix}`],
      row_padding_top: this.state.data[`row_padding_top${prefix}`],
      viewport: viewport,
    });
  }

  render () {
    return (
      <div>
        <div
          className="module"
          style={{
            paddingTop: this.state.row_padding_top+'px',
            paddingBottom: this.state.row_padding_bottom+'px',
            backgroundColor: this.state.background,
            backgroundImage: this.state.bg_image ? 'url('+this.state.bg_image+')' : 'none',
            backgroundPosition: this.state.bg_image_x+'% '+this.state.bg_image_y+'%'
          }}>

          <ShareLocation module={this.state.data.id} />

          {/* Mobile scroll hint */}
          {!this.state.data.data_mobile && (
            <div className="mobile-scroll-hint" onTouchStart={this.hideScrollHint}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 30">
                <g>
                  <path d="M8,19c-0.3,0-0.5-0.1-0.7-0.3l-6-6c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l6-6c0.4-0.4,1-0.4,1.4,0
                    c0.4,0.4,0.4,1,0,1.4l0,0L3.4,12l5.3,5.3c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0C8.5,18.9,8.3,19,8,19z M16.7,18.7l6-6
                    c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0l-6-6c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l5.3,5.3l-5.3,5.3c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0
                    C15.7,19.1,16.3,19.1,16.7,18.7C16.7,18.7,16.7,18.7,16.7,18.7z"/>
                </g>
              </svg>
            </div>
          )}

          {/* Generate content */}
          {this.state.json && (
            <div className={'module_figma' + (!this.state.data_mobile ? ' mobile-layout-horizontal' : '')}>
              <div className="content-area" style={{'height':this.state.height+'px', 'transform':'scale('+this.state.viewport+')'}}>
                {this.state.json.map((node, index) => (
                  <TreeNode key={index} node={node} items={this.state.data.items} viewport={this.state.viewport} props={this.props} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Figma;
